<template>
  <v-col :cols="cols">
    <v-sheet
      rounded
      outlined
      color="lighten-3"
      class="v-label-input px-2 py-2 text-right"
    >
      {{value | toCurrency}}
    </v-sheet>
  </v-col>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    cols: {
      type: Number,
      default: 2
    }
  },
};
</script>